import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { exhaustMap, map, of, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { environment } from '@env/environment';
import { BimModel, BimModelActions, bimModelFeature } from '@app/calculators/kv-to-bim/store/bim-model';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { concatLatestFrom } from '@ngrx/operators';

@UntilDestroy()
@Injectable()
export class BimModelEffects {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly store = inject(Store);
  private readonly actions$: Actions = inject(Actions);
  private pollingSuccess$ = new Subject();

  loadBimModel$ = createEffect((actions$ = inject(Actions)) => {
    const http = inject(HttpClient);
    return actions$.pipe(
      // ofType(BimModelActions.bimModelUploadSuccess),
      ofType(BimModelActions.bimModelSelected),
      concatLatestFrom(() => this.store.select(bimModelFeature.selectBimModel)),
      concatMap(([request, filters]) =>
        http
          .post<BimModel>(`${environment.serverUrl}/api/bim/model`, { id: filters != null ? filters.id : request.id })
          .pipe(
            untilDestroyed(this),
            map((bimModel: BimModel) => {
              this.pollingSuccess$.next('done');
              this.pollingSuccess$.complete();
              return BimModelActions.bimModelLoadedSuccess({ bimModel: bimModel });
            }),
            catchError((error) => of(BimModelActions.bimModelLoadedError({ error: error }))),
          ),
      ),
    );
  });

  loadBimModelById$ = createEffect(
    (actions$ = inject(Actions)) => {
      const http = inject(HttpClient);
      return actions$.pipe(
        ofType(BimModelActions.bimModelLoading),
        switchMap((request) =>
          http.post<BimModel>(`${environment.serverUrl}/api/bim/model`, { id: request.id }).pipe(
            untilDestroyed(this),
            map((bimModel: BimModel) => {
              return BimModelActions.bimModelLoadedSuccess({ bimModel: bimModel });
            }),
            catchError((error) => of(BimModelActions.bimModelLoadedError({ error: error }))),
          ),
        ),
      );
    },
    { functional: true },
  );

  pollBimModel$ = createEffect(
    (actions$ = inject(Actions)) => {
      const http = inject(HttpClient);
      this.pollingSuccess$ = new Subject();
      // timer(0, 600000);
      return actions$.pipe(
        ofType(BimModelActions.bimModelUploadSuccess),
        switchMap(() => {
          const stopPolling$ = timer(600000).pipe(takeUntil(this.pollingSuccess$));
          return timer(20_000, 7000).pipe(
            takeUntil(stopPolling$),
            takeUntil(this.pollingSuccess$),
            tap((number) => console.log('Polling number: ', number)),
            untilDestroyed(this),
            concatLatestFrom(() => this.store.select(bimModelFeature.selectBimModel)),
            switchMap(([number, filters]) => {
              if (filters != null && filters.id != null) {
                return http.post<BimModel>(`${environment.serverUrl}/api/bim/model`, { id: filters.id }).pipe(
                  untilDestroyed(this),
                  // retry({ count: 15, delay: 20000 }),
                  map((bimModel: BimModel) => {
                    this.pollingSuccess$.next('done');
                    this.pollingSuccess$.complete();
                    return BimModelActions.bimModelLoadedSuccess({ bimModel: bimModel });
                  }),
                  catchError((error) => of(BimModelActions.bimModelLoadedError({ error: error }))),
                );
              } else {
                return of(BimModelActions.bimModelStillPolling({ msg: `Polling  number:  ${number}` }));
              }
            }),
          );
          // },
        }),
      );
    },
    { functional: true },
  );

  enrichBimModel$ = createEffect(
    (actions$ = inject(Actions)) => {
      const http = inject(HttpClient);
      return actions$.pipe(
        ofType(BimModelActions.bimModelEnrich),
        exhaustMap((requestDTO) => {
          console.error(requestDTO);
          const req = { id: requestDTO.id };
          return http.post<BimModel>(`${environment.serverUrl}/api/bim/enrich`, req).pipe(
            untilDestroyed(this),
            map((bimModel: BimModel) => {
              // return BimModelActions.bimModelLoadedSuccess({ bimModel: bimModel });
            }),
            // catchError((error) => of(BimModelActions.bimModelLoadedError({ error: error }))),
          );
        }),
      );
    },
    { functional: true, dispatch: false },
  );
  TestBimModel$ = createEffect(
    (actions$ = inject(Actions)) => {
      const http = inject(HttpClient);
      return actions$.pipe(
        ofType(BimModelActions.bimModelTest),
        exhaustMap((requestDTO) => {
          console.error(requestDTO);
          const req = { id: requestDTO.id };
          return http.post<BimModel>(`${environment.serverUrl}/api/bim/test`, req).pipe(
            untilDestroyed(this),
            map((bimModel: BimModel) => {
              // return BimModelActions.bimModelLoadedSuccess({ bimModel: bimModel });
            }),
            // catchError((error) => of(BimModelActions.bimModelLoadedError({ error: error }))),
          );
        }),
      );
    },
    { functional: true, dispatch: false },
  );
  loadAllBimModelMetaData$ = createEffect(
    (actions$ = inject(Actions)) => {
      const http = inject(HttpClient);
      return actions$.pipe(
        ofType(BimModelActions.bimModelsLoadAll),
        exhaustMap((bimModelId) =>
          http.get<BimModel[]>(`${environment.serverUrl}/api/bim/models`).pipe(
            // untilDestroyed(this),
            map((bimModels) => {
              return BimModelActions.bimModelsLoadedSuccess({ bimModels: bimModels });
            }),
          ),
        ),
      );
    },
    { functional: true },
  );
  uploadBimModel$ = createEffect(
    (actions$ = inject(Actions)) => {
      const http = inject(HttpClient);
      return actions$.pipe(
        ofType(BimModelActions.bimModelUpload),
        tap((data) => console.log('data bimModelUpload: ', data)),
        exhaustMap((data) =>
          http
            .post<BimModel>(`${environment.serverUrl}/api/bim/upload`, data.formData, {
              reportProgress: true,
              observe: 'events',
            })
            .pipe(
              untilDestroyed(this),
              tap((data) => console.log('data bim upload http: ', data)),
              map((event) => this.getActionFromHttpEvent(event)),
            ),
        ),
      );
    },
    { functional: true },
  );

  private getActionFromHttpEvent(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent: {
        return BimModelActions.bimModelUploadStarted();
      }
      case HttpEventType.UploadProgress: {
        return BimModelActions.bimModelUploadProgress({
          progress: Math.round((100 * event.loaded) / (event.total ?? 1)),
        });
      }
      case HttpEventType.ResponseHeader:
      case HttpEventType.Response: {
        if (event.status === 200) {
          let ret = -1;
          var castedEvent = event as HttpResponse<any>;
          if (castedEvent.body != null && castedEvent.body.id != null) {
            ret = castedEvent.body.id;
          }
          let modelName = '';
          if (castedEvent.body != null && castedEvent.body.modelName != null) {
            modelName = castedEvent.body.modelName;
          }
          return BimModelActions.bimModelUploadSuccess({ id: ret, modelName: modelName });
        } else {
          return BimModelActions.bimModelUploadError({ error: `Unknown Event: ${JSON.stringify(event)}` });
        }
      }
      default: {
        return BimModelActions.bimModelUploadError({ error: `Unknown Event: ${JSON.stringify(event)}` });
      }
    }
  }
}
